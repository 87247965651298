import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import {
  ProvidersService,
  UserPreferencesService,
  LocationService
} from '../../shared/services';

import { Provider, Location } from '../../shared/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  isLoading = false;
  providers: Array<Provider> = [];

  constructor(
    private providersService: ProvidersService,
    private locationService: LocationService,
    private userPreferencesService: UserPreferencesService,
    private router: Router) { }

  ngOnInit() {

    this.isLoading = true;

    this.providersService.getProviders().pipe(finalize(() => this.isLoading = false))
      .subscribe((response) => {

        this.providers = response.providers.filter((provider: Provider) => {
          let isAllowed = provider.roles.includes("owner") || provider.roles.includes("admin") || provider.roles.includes("manager");
          return provider.active && isAllowed
        });

        this.providers.forEach((provider: Provider) => {
          this.locationService.getLocations(provider.id).subscribe((locations: Location[]) => {
            provider.locations = locations.filter((location: Location) => {
              return (location.active);
            });
          });
        });
      });

  }

  selectLocation(provider: Provider, location: Location) {
    this.setUserPreferences(provider, location);
    this.router.navigate(['./dashboard/' + provider.slug + '/location/' + location.id]);
  }

  monitoring(provider: Provider, location: Location) {
    this.setUserPreferences(provider, location);
    this.router.navigate(['monitor/' + provider.slug]);
  }

  private setUserPreferences(provider: Provider, location: Location) {
    this.userPreferencesService.clearValue('current-location');
    this.userPreferencesService.clearValue('current-provider');

    this.userPreferencesService.set('current-location', location);
    this.userPreferencesService.set('current-provider', provider);
  }
}
