<footer class="noprint">
  <div class="icon-list">
    <a class="logo" [routerLink]="['/home']">
      <img src="assets/images/logo/fz-logo-white.svg" alt="Filazero">
    </a>
    <a class="social-icon" href="https://www.instagram.com/filazeroapp/?hl=pt-br" target="_blank">
      <i class="fa fa-instagram" aria-hidden="true"></i>
    </a>
    <a class="social-icon" href="https://pt-br.facebook.com/filazero/" target="_blank">
      <i class="fa fa-facebook-official" aria-hidden="true"></i>
    </a>
    <a class="social-icon" href="https://www.linkedin.com/company/filazero/" target="_blank">
      <i class="fa fa-linkedin" aria-hidden="true"></i>
    </a>
  </div>

  <div class="text-list">
    <span>Copyright &copy; 2018 Filazero</span>
    <a [routerLink]="['/user/terms']">{{'terms' | translate}}</a>
  </div>
</footer>
