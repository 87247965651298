import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  constructor() { }

  getInformation() {
    return JSON.parse(localStorage.getItem('user-preferences')) || {};
  }

  get(key: string) {
    const currentUserPreferences = this.getInformation();
    return currentUserPreferences[key];
  }

  set(key: string, value: any) {
    const userPreferences = this.getInformation();
    userPreferences[key] = value;
    localStorage.setItem('user-preferences', JSON.stringify(userPreferences));
  }

  clearValue(key: string) {
    const userPreferences = this.getInformation();
    userPreferences[key] = undefined;
    localStorage.setItem('user-preferences', JSON.stringify(userPreferences));
  }
}
