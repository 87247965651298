import { Component, OnInit } from '@angular/core';
import { UserPreferencesService, MonitorService } from 'src/app/shared/services';
import { Provider } from 'src/app/shared/models';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitoring-panels.component.html',
  styleUrls: ['./monitoring-panels.component.sass']
})
export class MonitoringPanelsComponent implements OnInit {

  provider: Provider;
  isLoading = false;

  constructor(
    private userPreferences: UserPreferencesService,
    private monitorService: MonitorService
    ) {

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.provider = this.userPreferences.get('current-provider');
    this.getLocationsStatus();
  }

  private getLocationsStatus() {
    this.monitorService.getLocationsStatus(this.provider.id).pipe(finalize(() => this.isLoading = false))
      .subscribe((data: any[]) => {
        this.provider.locations = data;
        this.verifyQueueStatus();
        this.getTotalTickets();
        this.getAverageUnitServiceTime();
        this.orderByWaitTimeAlert();
      });
  }

  private verifyQueueStatus() {
    this.provider.locations.forEach(l => {
      l.status = this.getStatusLocationByQueue(l.serviceConfigs);
    });
  }

  private getStatusLocationByQueue(queues: any[]) {
    const attendances = [];
    queues.forEach(q => {
      if (q.attendance) {
        attendances.push(q.attendance);
      }
    });
    return Math.max.apply(Math, attendances.map(a => a.status));
  }

  private getTotalTickets() {
    this.provider.locations.forEach( l => {
      l.totalTickets = this.sumTicketsByQueue(l.serviceConfigs);
    });
  }

  private sumTicketsByQueue(queues: any[]) {
    let sum = 0;
    queues.forEach(q => {
      if (q.attendance) {
        sum += q.attendance.ticketsCompleted;
      }
    });
    return sum;
  }

  getAverageUnitServiceTime() {
    this.provider.locations.forEach( l => {
      l.averageWaitTime = this.averageServiceTime(l.serviceConfigs) || 0;
    });
  }

  private averageServiceTime(queues: any[]) {
    let count = 0, sum = 0;
    queues.forEach(q => {
      if (q.attendance) {
        sum += q.attendance.averageWaitTime;
        count++;
      }
    });
    return Math.floor((sum / count));
  }

  private orderByWaitTimeAlert() {
    this.provider.locations.forEach(l => {
      l.serviceConfigs = l.serviceConfigs.sort((a, b) => {
        if (a.attendance && b.attendance) {
          return (b.attendance.status - a.attendance.status);
        } else {
          b.attendance = {};
          a.attendance = {};
          b.attendance.status = 0;
          a.attendance.status = 0;
          return (b.attendance.status - a.attendance.status);
        }
      });
    });
  }
}
