<section>
  <header>
    <h1 *ngIf="isLoading" class="title-section">Carregando...</h1>
    <h1 *ngIf="!isLoading && providers.length === 1" class="title-section">Seleção de Unidades</h1>
    <h1 *ngIf="!isLoading && providers.length > 1" class="title-section">{{'title.company' | translate }}</h1>
  </header>

  <span class="spinner" *ngIf="isLoading"></span>

  <article *ngIf="!isLoading">
    <mat-accordion *ngIf="providers.length > 1">
      <mat-expansion-panel *ngFor="let provider of providers">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ provider.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list>
          <mat-list-item class="featured" (click)="monitoring(provider, location)">Monitorar Unidades</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item (click)="selectLocation(provider, location)" *ngFor="let location of provider.locations">
            {{ location.name }} - Relatórios
          </mat-list-item>
        </mat-nav-list>

      </mat-expansion-panel>
    </mat-accordion>

    <div class="grid" *ngIf="providers.length === 1">
      <mat-card class="location" *ngFor="let location of providers[0].locations" (click)="selectLocation(providers[0], location)">
        <mat-card-header>
            <mat-card-title>
              {{ location.name }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            {{ location.address }}
          </mat-card-content>
      </mat-card>
    </div>

    <div class="flex not-found" *ngIf="providers.length === 0">
      <mat-icon>highlight_off</mat-icon>
      <p>{{'messages.info.no-providers-found' | translate }}</p>
    </div>
  </article>
</section>
