import { Component, OnInit, Input } from '@angular/core';
import { MonitorService, UserPreferencesService } from 'src/app/shared/services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-monitoring-card',
  templateUrl: './monitoring-card.component.html',
  styleUrls: ['./monitoring-card.component.sass']
})
export class MonitoringCardComponent implements OnInit {

  @Input('location') location: any;
  showDetails = false;
  isLoading = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }
}
