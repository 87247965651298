import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {

  API_URL_V2: string = environment.API_URL_V2;

  constructor(private readonly http: HttpClient) { }

  getLocationsStatus(providerId: number): Observable<any> {
    return new Observable(obs => {
      this.http.get(`${this.API_URL_V2}insights/providers/${providerId}/locations-status`)
        .subscribe(
          (response: any[]) => {
            obs.next(this.buildLocationsList(response));
            obs.complete();
          }
        );
    });
  }

  private buildLocationsList(locations: any[]) {

    let lcts = [];

    lcts = locations.map(loc => this.buildLocation(loc));
    return lcts;
  }

  private buildLocation(data: any) {
    switch (data.status) {
      case 0:
        data.status = 'grey';
        break;
      case 1:
        data.status = 'orange';
        break;
      case 2:
        data.status = 'red';
        break;
      case 3:
        data.status = 'green';
    }
    return data;
  }

}
