<mat-card class="location" [attr.data-status]="location.status">
  <mat-card-header>
    <mat-card-title>
      {{ location.name }} - Total de Atendimentos: {{location.totalTickets}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <i class="fa fa-map-marker" aria-hidden="true"></i>
    {{ location.address }}
    <table class="table">
      <tr>
        <th>TEMin</th>
        <td>{{location.minWaitTime}}min</td>
        <th>TEMax</th>
        <td>{{location.maxWaitTime}}min</td>
        <th>TMA</th>
        <td>{{location.averageWaitTime}}min</td>
      </tr>
    </table>
    <a mat-raised-button (click)="toggleDetails()">{{showDetails ? 'Ocultar' : 'Ver Mais'}}</a>
    <div *ngIf="showDetails">
      <mat-nav-list>
        <mat-list-item [attr.data-status]="sc.attendance?.status" class="queue" *ngFor="let sc of location.serviceConfigs">{{sc.name}}</mat-list-item>
      </mat-nav-list>
    </div>
  </mat-card-content>
</mat-card>
