import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserPreferencesService } from './user-preferences.service';
import { PerformanceOperator } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  API_URL_V2: string = environment.API_URL_V2;

  private location;
  private provider;

  constructor(private http: HttpClient,
    private _userPreferencesService: UserPreferencesService) { }

  getServicesResources() {
    this.retrieveInformation();
    return this.http.get(`${this.API_URL_V2}insights/providers/${this.provider.id}/locations/${this.location.id}/dashboard/servicesResources`);
  }

  getDataFromServer(params): Observable<any> {
    return this.http.get(`${this.API_URL_V2}insights/providers/${this.provider.id}/locations/${this.location.id}/dashboard/classMedia`, {params});
  }

  getDataPerformFromServer(params): Observable<any> {
    return this.http.get(`${this.API_URL_V2}insights/providers/${this.provider.id}/locations/${this.location.id}/dashboard/performTotal`, {params});
  }

  getDataPerformPerServiceFromServer(params): Observable<any> {
    return this.http.get(`${this.API_URL_V2}insights/providers/${this.provider.id}/locations/${this.location.id}/dashboard/performSrvc`, {params});
  }

  getDataAvgRateFromServer(params): Observable<any> {
    return this.http.get(`${this.API_URL_V2}insights/providers/${this.provider.id}/locations/${this.location.id}/dashboard/avgRate`, {params});
  }

  getDataRushHourFromServer(params): Observable<any> {
    return this.http.get(`${this.API_URL_V2}insights/providers/${this.provider.id}/locations/${this.location.id}/dashboard/rushHour`, {params});
  }

  getAttendancesFromServer(params): Observable<any> {
    return this.http.get(`${this.API_URL_V2}insights/providers/${this.provider.id}/locations/${this.location.id}/dashboard/attendances`, {params});
  }

  getFeedbacksDetails(params): Observable<any> {
    return this.http.get(`${this.API_URL_V2}insights/providers/${this.provider.id}/locations/${this.location.id}/dashboard/feedbacks`, {params});
  }

  retrieveInformation() {
    this.location = this._userPreferencesService.get('current-location');
    this.provider = this._userPreferencesService.get('current-provider');
  }

  getProviderName() {
    return this.provider.name;
  }

  getLocationName() {
    return this.location.name;
  }

  getPerformanceTable(params) {
    const url = `${this.API_URL_V2}insights/providers/${this.provider.id}/locations/${this.location.id}/dashboard/scheduling-attendants-services`;
    return this.http.get<Array<any>>(url, {params});
  }

  getPerformanceOperator(params) : Observable<PerformanceOperator[]> {
    const url = `${this.API_URL_V2}insights/providers/${this.provider.id}/locations/${this.location.id}/dashboard/scheduling-operator-services`;
    return this.http.get<PerformanceOperator[]>(url, {params});
  }
}
