<section>
  <header>
    <h1 class="title-section">{{ provider.name }}</h1>
  </header>

  <span class="spinner" *ngIf="isLoading"></span>

  <article *ngIf="!isLoading">
    <div class="grid">
      <app-monitoring-card
        *ngFor="let location of provider.locations"
        [location]="location">
      </app-monitoring-card>
    </div>
  </article>
</section>
